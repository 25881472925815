#photography-container {
    font-family: 'Montserrat', sans-serif;
    background-color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

#category-list {
    z-index: 696;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    width: 100%;
    margin: 0;
    padding: 0;
}

.category-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.photos-header {
    padding-top: 10px;
    color: var(--text);
    font-weight: 800;
    font-size: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#photos {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 10px;
    -moz-column-count: 3;
    -moz-column-gap: 10px;
    column-count: 3;
    column-gap: 10px;
    width: 100%;
}

#photos img {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100% !important;
    height: auto !important;
}

.back-arrow {
    color: white;
    transition-duration: 0.3s;
    font-size: 28px;
}

.back-arrow:hover {
    transition-duration: 0.3s;
    color: rgb(170, 170, 170);
}

@media (max-width: 1000px) {
    #photos {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

#landscape {
    background-image: url('../../../images/landscape.jpg');
}

#portraits {
    background-image: url('../../../images/portraits.JPG');
}

#animals {
    background-image: url('../../../images/animals.png');
}

#misc {
    background-image: url('../../../images/misc.jpeg');
}

.category-link:hover {
    color: var(--text);
    transition-duration: 0.4s;
    background-color: rgba(0, 0, 0, 0.6);
}

.category-link {
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: 900;
    color: var(--text);
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    padding: 0.75em;
    transition-duration: 900ms;
    &::before,
    &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform-origin: center;
        transition-duration: 900ms;
    }

    &::before {
        border-top: 0.1em solid white;
        border-bottom: 0.1em solid white;
        transform: scale3d(0, 1, 1);
        transition-duration: 900ms;
    }

    &::after {
        border-left: 0.1em solid white;
        border-right: 0.1em solid white;
        transform: scale3d(1, 0, 1);
        transition-duration: 900ms;
    }

    &:hover::before,
    &:hover::after {
        transform: scale3d(1, 1, 1);
        transition: transform 900ms;
    }
}

@media (max-width: 600px) {
    #photos {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }

    #category-list {
        padding: 0;
    }

    .category-link {
        margin-left: 0;
        font-size: 35px;
        padding: 0.5em;
    }

    #photos {
        padding-left: 10px;
    }

    .photos-header {
        font-size: 30px;
        padding-top: 25px;
    }

    #portraits-container .photos-header {
        font-size: 26px;
    }
}
