#misc-container {
    background-color: var(--dark);
}

#misc-container #photos {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
}

@media (max-width: 600px) {
    #misc-container #photos {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}
