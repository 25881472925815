:root {
    --light: #7fe0b5;

    --darkerLight: #42675b;

    --dark: #282f41;

    --text: #ffffff;
}

.App {
    text-align: center;
}

body {
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

#cover {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.5s linear;
    transition-duration: 0.5s;
    z-index: -1;
}

#cover.covering {
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.5s linear;
    transition-duration: 0.5s;
    z-index: 6968;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
